// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["DqujYVn4J", "HzrmZlqjh"];

const variantClassNames = {DqujYVn4J: "framer-v-586dwu", HzrmZlqjh: "framer-v-i0zchg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "DqujYVn4J", "Variant 2": "HzrmZlqjh"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, PoM4YLkh3: tap ?? props.PoM4YLkh3, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "DqujYVn4J"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PoM4YLkh3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DqujYVn4J", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTappr6ygd = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("HzrmZlqjh")
})

const onTap1iknh31 = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("DqujYVn4J")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FnaA9", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-586dwu", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"DqujYVn4J"} onTap={onTappr6ygd} ref={ref} style={{...style}} {...addPropertyOverrides({HzrmZlqjh: {"data-framer-name": "Variant 2", onTap: onTap1iknh31}}, baseVariant, gestureVariant)}><motion.div className={"framer-16izvi8"} layoutDependency={layoutDependency} layoutId={"rBucHavVf"}><motion.div className={"framer-1rcmh8x"} layoutDependency={layoutDependency} layoutId={"zyLAvMgWq"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{HzrmZlqjh: {rotate: 45}}}/><motion.div className={"framer-1og3qp9"} layoutDependency={layoutDependency} layoutId={"GjytMpRrY"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{HzrmZlqjh: {rotate: -45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FnaA9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FnaA9 .framer-q5zu4n { display: block; }", ".framer-FnaA9 .framer-586dwu { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 32px; }", ".framer-FnaA9 .framer-16izvi8 { flex: none; height: 14px; overflow: visible; position: relative; width: 20px; }", ".framer-FnaA9 .framer-1rcmh8x { flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-FnaA9 .framer-1og3qp9 { bottom: 0px; flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FnaA9 .framer-586dwu { gap: 0px; } .framer-FnaA9 .framer-586dwu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FnaA9 .framer-586dwu > :first-child { margin-left: 0px; } .framer-FnaA9 .framer-586dwu > :last-child { margin-right: 0px; } }", ".framer-FnaA9.framer-v-i0zchg .framer-586dwu { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-FnaA9.framer-v-i0zchg .framer-1rcmh8x, .framer-FnaA9.framer-v-i0zchg .framer-1og3qp9 { bottom: 6px; height: unset; left: unset; top: 5px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HzrmZlqjh":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PoM4YLkh3":"tap"}
 */
const FramerH5aknpodK: React.ComponentType<Props> = withCSS(Component, css, "framer-FnaA9") as typeof Component;
export default FramerH5aknpodK;

FramerH5aknpodK.displayName = "Icons/AnimatedMenuBurger";

FramerH5aknpodK.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerH5aknpodK, {variant: {options: ["DqujYVn4J", "HzrmZlqjh"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, PoM4YLkh3: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerH5aknpodK, [])